import request from '@/utils/request'


// 查询商家退货地址列表
export function listAddr(query) {
  return request({
    url: '/zb/addr/list',
    method: 'get',
    params: query
  })
}

// 查询商家退货地址分页
export function pageAddr(query) {
  return request({
    url: '/zb/addr/page',
    method: 'get',
    params: query
  })
}

// 查询商家退货地址详细
export function getAddr(data) {
  return request({
    url: '/zb/addr/detail',
    method: 'get',
    params: data
  })
}

// 新增商家退货地址
export function addAddr(data) {
  return request({
    url: '/zb/addr/add',
    method: 'post',
    data: data
  })
}

// 修改商家退货地址
export function updateAddr(data) {
  return request({
    url: '/zb/addr/edit',
    method: 'post',
    data: data
  })
}

// 删除商家退货地址
export function delAddr(data) {
  return request({
    url: '/zb/addr/delete',
    method: 'post',
    data: data
  })
}
